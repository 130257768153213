<template>
  <div class="publishProject">
    <div>发布项目</div>
    <div class="demo-ruleForm">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="149px" label-color="#999">
        <el-form-item label="上传图片：" prop="legalPerson">
          <UploadImg :showType="'publishProject'" @singleImage="singleImage" />
        </el-form-item>
        <el-form-item label="合作对象：" prop="category">
          <el-cascader
            v-model="ruleForm.category"
            :options="cateList"
            separator="-"
            :popper-class="'name'"
            :props="{ expandTrigger: 'hover' }"
            @getCheckedNodes="expandChange"
            @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="面向区域：" prop="area">
          <el-select v-model="ruleForm.area" placeholder="请选择">
            <el-option
              v-for="item in cityMembersList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称：" prop="projectName">
          <el-input v-model="ruleForm.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="剂型：" prop="dosageForm">
          <el-input v-model="ruleForm.dosageForm" placeholder="请输入剂型"></el-input>
        </el-form-item>
        <el-form-item label="规格：">
          <el-input v-model="ruleForm.specification" placeholder="请输入规格"></el-input>
        </el-form-item>
        <el-form-item label="科室：">
          <el-input v-model="ruleForm.department" placeholder="请输入科室"></el-input>
        </el-form-item>
        <el-form-item label="适应症：" prop="indications">
          <el-input v-model="ruleForm.indications" placeholder="请输入适应症"></el-input>
        </el-form-item>
        <el-form-item label="项目进展：" prop="progress">
          <el-input v-model="ruleForm.progress" placeholder="请输入项目进展"></el-input>
        </el-form-item>
        <el-form-item label="项目描述：" prop="describe">
          <el-input v-model="ruleForm.describe" type="textarea" autosize placeholder="请输入项目详细描述"></el-input>
        </el-form-item>
        <el-form-item label="合作意向：">
          <el-input v-model="ruleForm.intention" placeholder="请输入合作意向"></el-input>
        </el-form-item>
        <el-form-item label="公司名称：" prop="company">
          <el-input v-model="ruleForm.company" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人：" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="请输入联系人的联系电话"></el-input>
        </el-form-item>
        <el-form-item label="E-mail：">
          <el-input v-model="ruleForm.email" placeholder="请输入联系人的邮箱"></el-input>
        </el-form-item>
        <div class="fileWidth">
          <UploadFile @uploadFileList="uploadFileList" />
          <el-button class="subitBut" type="primary" @click="release('ruleForm')">发布</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import rules from './rules'
import UploadImg from '@/components/upload/uploadImg'
import UploadFile from '@/components/upload/uploadFile'
import { elementListList, cityList } from '@/api/index/index'
import { create, myInfo } from '@/api/user/user'
export default {
  name: 'publishProject',
  components: {
    UploadImg,
    UploadFile
  },
  data() {
    return {
      ruleForm: {
        category: '', // 类别
        area: '', // 区域
        projectName: '',
        dosageForm: '',
        specification: '',
        department: '',
        indications: '',
        progress: '',
        describe: '',
        intention: '',
        company: '',
        name: '',
        phone: '',
        email: '',
        pic: '',
        files: '' // 附件
      },
      rules: rules,
      cateList: [],
      cityMembersList: [],
      childrenList: [],
      pidOne: 0, // 一级id
      pidTwo: 0, // 二级id
      level: 0,
      info: {} // 个人信息
    }
  },
  mounted() {
    this.cateHome()
    this.regionalMmbers()
    window.addEventListener('setItemEvent', (e) => {
      this.info = JSON.parse(localStorage.getItem('userInfo'))
    })
  },
  created() {
    this.info = JSON.parse(localStorage.getItem('userInfo'))
    if (this.info) {
      this.ruleForm.name = this.info.nickName
      this.ruleForm.phone = this.info.phone
      this.ruleForm.email = this.info.contact_email
      if (this.info.is_certification !== 1) {
        myInfo().then(res => {
          if (res.data.is_certification !== 1) {
            this.$message.warning('请先实名认证')
            localStorage.setItem('userInfo', JSON.stringify(res.data));
            this.$router.push({name: 'myTracks', query: {notCertified: 'notCertified'}})
          }
        })
      }
    }
  },
  methods: {
    release(formName) { // 发布
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            pic: this.ruleForm.pic, // 封面图
            name: this.ruleForm.projectName,
            cate_id_one: this.ruleForm.category[0], // 一级分类ID
            cate_id_two: this.ruleForm.category[1], // 二级分类ID
            city_id: this.ruleForm.area, // 城市ID
            indications: this.ruleForm.indications, // 适应症
            progress: this.ruleForm.progress, // 项目进展
            describe: this.ruleForm.describe, // 描述
            company_name: this.ruleForm.company, // 公司名称
            contact_name: this.ruleForm.name, // 联系人
            contact_phone: this.ruleForm.phone, // 联系人手机
            specifications: this.ruleForm.specification, // 规格
            dosage: this.ruleForm.dosageForm, // 剂型
            department: this.ruleForm.department, // 科室
            cooperation: this.ruleForm.intention, // 合作意向
            contact_email: this.ruleForm.email, // 联系邮箱
            annex: this.ruleForm.files // 附件（,逗号分隔）
          }
          create(data).then(res => {
            if (res.code === 1) {
              this.$message.success('提交成功')
              const id = res.data.id
              this.$router.push({name: 'detailsProjesct', query: {id}})
            } else {
              this.$message.success(res.mes)
            }
          })
        } else {
          // console.log('error submit!!');
          return false;
        }
      })
    },
    singleImage(img) { // 单图
      // console.log('ig', img)
      this.ruleForm.pic = img
    },
    uploadFileList(file) { // 附件
      this.ruleForm.files = file.join(',')
    },
    handleChange(value) { // 联动 项目类别
      // console.log('11', value);
      this.pid = value.toString()
      this.level = 1
      this.cateHome()
    },
    expandChange(val) {
      // console.log('val', val)
    },
    regionalMmbers() {
      cityList().then(res => {
        this.cityMembersList = res.data
      })
    },
    cateHome() {
      elementListList().then(res => {
        this.cateList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/{
    .el-textarea .el-textarea__inner{
      min-height: 108px !important;
    }
  }
  .publishProject{
    background: #fff;
    margin-top: 12px;
    padding: 32px 24px;
    .demo-ruleForm{
      width: 620px;
      margin: 30px auto 0;
      .fileWidth{
        width: 520px;
        margin: auto;
        .subitBut{
          width: 180px;
          margin: 40px  0 0 170px;
        }
      }
      /deep/{
        .el-form-item__content{
          width: 420px;
        }
        .el-select{
          width: 100%;
        }
        .el-cascader{
          width: 100%;
        }
      }
    }
  }
</style>
